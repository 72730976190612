<template>
  <div style="background: #F7F7F7">
    <v-container class="ma-0 pa-0" fluid>
      <v-layout>
        <v-flex sm2></v-flex>
        <v-flex xs12 sm8 class="px-3 pt-lg-7 pt-5">
          <v-layout row pa-2>
            <v-card-text class="font-weight-bold text-h5 pt-1 pb-0">What coverage would you like to claim for?</v-card-text>
          </v-layout>
          <v-layout row pa-2>
            <v-flex xs12 sm12 md6 pa-2
                    v-for="incident in incident_items"
                    v-bind:key="incident.id">
              <v-card
                  height="100%"
                  class="px-6 py-4"
                  @click="saveIncidentType(incident)"
                  v-bind:class="{ active: incident.isActive }"
                  active-class="default-class"
              >
                <v-row align="center"
                       style="min-height: 100px;">
                  <v-col class="pa-0" cols=9>
                    <v-card-title class="font-weight-bold">{{ incident.title }}</v-card-title>
                  </v-col>
                  <v-col cols="3" align="right" class="pr-8 ">
                    <v-img :src="icons.right_arrow_icon" aspect-ratio="1" height="26" width="14"></v-img>
                  </v-col>

                </v-row>
                <v-row class="pa-0 ma-0">
                  <v-col class="pa-1">
                    <v-divider class="my-n2"></v-divider>
                  </v-col>
                </v-row>

                <v-row
                    no-gutters
                    class="pa-1 pt-4 "
                >
                  <v-col class="pa-0 pa-0"
                         cols="8"
                  >
                    <v-card-subtitle
                        class="ma-0 pa-0"
                    >{{ incident.content }}
                    </v-card-subtitle>
                  </v-col>
                  <v-col
                      cols="4"
                  >
                    <v-row justify="end" class="mr-0 mr-md-n1 mr-lg-4">

                      <v-card
                          elevation="0"
                          :style="{'background-color': incident.background_color_code}"
                          :class="$vuetify.breakpoint.lgAndUp?'desktop-circle':'mobile-circle'"
                          class="d-flex align-center flex-column">
                          <v-img :src="incident.imageUrl" width=50
                               contain style="border-radius:0px"/>
                      </v-card>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="incident.isContainsExtraContent">
                  <v-col cols="12">
                    <v-divider class="ma-0 pa-0"></v-divider>
                  </v-col>
                  <v-col cols="12" class="my-0 py-0">
                    <v-card-text class="red--text pa-0 pb-1 font-weight-bold">
                      <img src="@/assets/icons/required.png" style="max-width: 12px"/> Note :
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" class="pa-0 ma-0">
                    <v-card-text class="my-0 pt-0 pb-3 primary--text"  style="font-size: 14px">
                      The loss of or damage to personal property must have been caused by an assault. Report to police
                      and medical consultation are required.
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog teleport="body"
        v-model="dialog"
              persistent
        :width="$vuetify.breakpoint.smAndDown?'90%':429"
    >
      <v-card
          color="white"
          class="pt-4 ma-0"
          style="box-shadow: 3px 4px 5px #00000033;border: 2px solid #006D9E !important;border-radius: 10px; padding: 2px"
      >
<!--        <v-icon style="float: right;padding-right: 16px" @click="this.agreeUseOfUAT">clear</v-icon>-->
        <v-card-text class="px-4 pt-10 pb-4 text-body-1 red--text text-center font-weight-bold" >
          This is Test (UAT) Site.
        </v-card-text>
        <v-card-text class="px-4 pt-2  text-body-1  text-center">
          <p>If you're want to claim for Marsh,</p>
          <p>Please visit <a href="https://deliveroo.marsh.com/claims-process"  style="color: #00CCBC">Official Deliveroo Site.</a></p>
        </v-card-text>
        <div style="background: #F2FBFC 0% 0% no-repeat padding-box; height: 77px; padding-top: 5px !important;">
          <v-row justify="center" align-content="center" class="ma-0 pa-0">
            <v-col cols="0" align-self="center">
              <v-btn
                  color="#00CCBC"
                  class="ma-0 white--text text-body-1"
                  large
                  block
                  @click="this.agreeUseOfUAT">
                Proceed to test.
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {toLower} from "lodash/string";

export default {
  components: {},
  data() {
    return {
      dialog:false,
      icons: {
        personal_accident_icon: require("@/assets/icons/icon_Image_00.png"),
        public_liability_icon: require("@/assets/icons/icon_Image_01.png"),
        right_arrow_icon: require("@/assets/icons/icon_select_arrow_01.png"),
        required_icon: require("@/assets/icons/required.png")
      },
      incident_items: [
        {
          id: "0",
          title: "Personal Accident and Income Protection",
          content:
              "This policy pays if you have an accident whilst working with deliveroo, and you can’t work as a result of your injury. You can claim for lost income whilst you can't work, and a lump sum payment for bodily injuries.",
          imageUrl: require("@/assets/icons/icon_Image_00.png"),
          background_color_code: "#EDF7FF",
          incidentcode: "PAIP",
          routename: "ClaimsDeclaration",
          next_route:
              "/claims-agreement",
          isActive: false
        },
        {
          id: "1",
          title: "Public Liability",
          content:
              "This policy provides protection in case you have an accident and cause injury to third party bodily injury and property damage when you are riding your device or walking to collect and deliver orders.  Motor Liability is excluded.",
          imageUrl: require("@/assets/icons/icon_Image_01.png"),
          background_color_code: "#E5FAF8 ",
          incidentcode: "PL",
          next_route: "/claims-public-liability",
          routename: "Claim_public_liability",
          isActive: false,
          isContainsExtraContent: true
        }
      ]
    };
  },
  methods: {
    initPageTitle: function () {
      this.$store.commit("changeMenuTitle", "Make a Claim");
    },
    initSubMenuTitle: function () {
      this.$store.commit("changeSubMenuTitle", "Incident Type");
    },
    changeIsActiveState: function (e) {
      e.isActive = !e.isActive;
    },
    initTopProgressStep: function () {
      this.$store.commit("changeStep", 1);
    },
    saveIncidentType(item) {
      this.$store.commit("saveIncidentType", item.incidentcode);
      this.$router.push(item.next_route);
    },
    ShowClaimProgressBar: function () {
      this.$store.commit("changeShowClaimProgressBarStatus", false);
    },
    checkIfUATSite() {
      const isLocalHostOrUAT = toLower(window.location.hostname)==='uat-deliveroo.marsh.com'
          ||toLower(window.location.hostname)==='localhost'
      if (isLocalHostOrUAT){
        if (this.$store.getters.uatAgreed!=='true')
        this.dialog= true
      }

    },
    agreeUseOfUAT(){
      this.$store.commit('agreeUseOfUAT')
      this.dialog = false
    }
  },
  mounted() {
    this.initPageTitle();
    this.initSubMenuTitle();
    this.initTopProgressStep();
    this.ShowClaimProgressBar();
    this.checkIfUATSite()
  }
};
</script>
<style scoped>
* {
  text-transform: none !important;
  line-height: 1.2;
}

.v-card__title {
  line-height: 25px;
  letter-spacing: 0px;
  word-break: break-word;
  color: #37424A;
  font-size: 23px;
}

.v-card__subtitle {
  line-height: 20px;
  letter-spacing: 0px;
  color: #37424A;
  font-size: 17px
}

.mobile-circle {
  border-radius: 100%;
  max-height: 82px;
  max-width: 82px;
  min-height: 82px;
  min-width: 82px;
}
.desktop-circle {
  border-radius: 50%;
  max-height: 94px;
  max-width: 94px;
  min-height: 94px;
  min-width: 94px;
}
</style>
