<template>
  <div class="my-body">
    <section class="banner-top hidden-xs">
      <div class="container" style="margin-top: 70px">
        <div class="row">
          <div class="col-xs-6 banner-left">
            <br>
            <br>
            <h1 v-html="$t('lang.claim-process.reportingAnIncidentWhenItMatters')">
            </h1>
            <div style="margin-left: 35%; margin-top: 30px;">
              <button id="claimButton" @click="$router.push('/claims-registration')" class="uppercase"
              style="font-size: 18px; font-family: 'Slate Pro Medium';letter-spacing: 0.5px;">{{$t('lang.common.reportAnIncident')}}
              </button>

            </div>
          </div>

          <div class="col-xs-6 banner-right claims-banner">
          </div>
        </div>
      </div>
    </section>

    <!-- for mobile -->
    <section class="banner-top-mobile hidden-lg hidden-sm hidden-md">
      <div class="">
        <div class="">
          <div class="bann-top-mobile-top cliam-bann">

          </div>
          <div class="bann-bottom-mobile-bt col-md-12">
            <h1 v-html="$t('lang.claim-process.reportingAnIncidentWhenItMatters')">
            </h1>
            <div class="col-md-12 text-center  hidden-sm hidden-md hidden-lg" id="mobile-claim-button">
              <router-link :to="'/claims-registration'" class="uppercase" style="color: #FFFFFF">{{ $t('lang.common.reportAnIncident') }}
              </router-link>
            </div>
            <div class="col-md-5 col-sm-6">

            </div>
          </div>

        </div>
      </div>
    </section>


    <section class="dosdonts">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-md-offset-1 col-sm-10 col-sm-offset-1 col-xs-12 text-center"
               v-html="$t('lang.claim-process.dosAndDonTsAtTheSceneOfTheAccident')">
          </div>

          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="media">
              <div class="media-left">
                <img src="@/assets/images/claims/icon_do_clock@2x.png" width="50"/>
              </div>
              <div class="media-body">
                <p>{{ $t('lang.claim-process.whenDidItHappenBePrecise') }}</p>
              </div>
            </div>

            <div class="media">
              <div class="media-left">
                <img src="@/assets/images/claims/icon_do_location@2x.png" width="50"/>
              </div>
              <div class="media-body">
                <p>{{ $t('lang.claim-process.whereDidItHappenProvideAddress') }}</p>
              </div>
            </div>

            <div class="media">
              <div class="media-left">
                <img src="@/assets/images/claims/icon_do_question@2x.png" width="50"/>
              </div>
              <div class="media-body">
                <p>{{ $t('lang.claim-process.whatHappenedProvideFullDetails') }}</p>
              </div>
            </div>

            <div class="media">
              <div class="media-left">
                <img src="@/assets/images/claims/icon_do_people@2x.png" width="50"/>
              </div>
              <div class="media-body">
                <p>{{ $t('lang.claim-process.whoIsInvolved') }}</p>
              </div>
            </div>

            <div class="media">
              <div class="media-left">
                <img src="@/assets/images/claims/icon_do_witness@2x.png" width="50"/>
              </div>
              <div class="media-body">
                <p>{{ $t('lang.claim-process.ifThereIsAWitness') }}</p>
              </div>
            </div>

            <div class="media">
              <div class="media-left">
                <img src="@/assets/images/claims/icon_do_camera@2x.png" width="50"/>
              </div>
              <div class="media-body">
                <p>{{ $t('lang.claim-process.takePictures') }}</p>
              </div>
            </div>

            <div class="media">
              <div class="media-left">
                <img src="@/assets/images/claims/icon_do_police@2x.png" width="50"/>
              </div>
              <div class="media-body">
                <p>{{ $t('lang.claim-process.callThePolice') }}</p>
              </div>
            </div>

            <div class="media">
              <div class="media-left">
                <img src="@/assets/images/claims/icon_DoNot@2x.png" width="50"/>
              </div>
              <div class="media-body">
                <p>{{ $t('lang.claim-process.doNotCompleteTheIncidentReportForm') }}</p>
              </div>
            </div>

            <div class="media">
              <div class="media-left">
                <img src="@/assets/images/claims/icon_DoNot@2x.png" width="50"/>
              </div>
              <div class="media-body">
                <p>{{ $t('lang.claim-process.doNotDiscussAnyIssuesOfLiability') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="advise-insurer">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center" v-html="$t('lang.claim-process.adviseInsurerMarshImmediatelySG')">
          </div>
        </div>
      </div>
    </section>

    <section class="how-data text-center">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h3>{{ $t('lang.claim-process.howTheDataYouSupplyIsUsed') }}</h3>
            <p><span v-html="$t('lang.claim-process.bySubmittingAnIncidentReportForm')"></span></p>
          </div>
        </div>
      </div>
    </section>
    <section class="footer-section hidden-lg">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-7" v-html="$t('lang.common.arrangedInPartnership')">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  components: {},
  data() {
    return {}
  },
  props: [],
  methods: {
    downloadPDF() {
      let country = this.$store.state.country
      let language = this.$store.state.selectedLanguageCode
      // set url
      let fileName
      let url
      switch (country) {
        case 'Hong Kong':
          if (language === 'hk') {
            fileName = '意外報告表格'
            // eslint-disable-next-line
            url = 'assets/pdf/HKIncidentReportForm_Chinese.PDF'
          } else {
            fileName = 'Incident Report Form'
            // eslint-disable-next-line
            url = 'assets/pdf/HKIncidentReportForm_English.pdf'
          }
          break
          // case 'Taiwan':
          //   fileName = '意外報告表格'
          //   url = './assets/pdf/TWIncidentReportForm.pdf'
          //   break
        default:
          // default SG
          fileName = 'Incident Report Form'
          // eslint-disable-next-line
          url = 'assets/pdf/IncidentReportForm.pdf'
      }
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob'
      }).then(response => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          let theFile = new Blob([response.data], {type: 'application/octet-stream'})
          window.navigator.msSaveOrOpenBlob(theFile, fileName + '.pdf')
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName + '.pdf')
          document.body.appendChild(encodeURIComponent(link))
          link.click()
          document.body.removeChild(link)
        }
      })
    },
    //    /**
    //    * Google Analytics fro Quote request
    //    * @param eventLabel Event label
    //    * @param eventAction Event Action
    //    */
    sendEvent(eventLabel, eventAction) {
      window.ga('send', 'event', {
        eventCategory: 'Deliveroo',
        eventLabel: eventLabel,
        eventAction: eventAction,
        eventValue: 10
      })
    }

  },
  computed: {},
  mounted(){
    this.$vuetify.goTo(0, {container: document.documentElement,duration:0})
  }
}
</script>
<style scoped>
#mobile-claim-button {
  background-color:#006EA2;
  padding: 10px 0;
  margin: 10PX 25% ;
  text-align:center;
  font-size:16px;
  width: 50%;
  font-family: 'Slate Pro Medium';
  letter-spacing: 0.5px;
}
</style>
