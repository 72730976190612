<template>
  <div  class="my-body group-personal" >
    <section class="sec_common sec">
      <div class="container" style="margin-top: 70px">
        <div class="row text-center">
          <div class="col-md-12">
            <br>
            <br>
            <br>
            <h1 :lang="getLang" :class="{boldText: getLang == 'zh'}">{{$t('lang.common.publicLiability')}}</h1>
            <p :lang="getLang">{{$t('lang.common.coversAllRidersWhenOffVehicle')}}</p>
            <button class="btn btn-one visible-sm visible-xs" @click="movePage('/claims-process')">
              <router-link :to="'/claims-process'" class="uppercase" :lang="getLang">{{$t('lang.common.reportAnIncident')}}</router-link>
            </button>
          </div>
        </div>
      </div>
    </section>

    <div class="clearfix"></div>

    <section class="sec_common sec_1">
      <div class="container">
        <div class="row">
          <div class="col-md-7 col-sm-12">
            <h3 :lang="getLang" :class="{boldText: getLang == 'zh'}">{{$t('lang.common.policySummary')}}</h3>
            <p :lang="getLang">{{$t('lang.liability.allRidersWillBeAutomaticallyEnrolled')}}</p>
          </div>
          <div class="col-md-5 col-sm-6">
            <!-- benefit pdf button -->
            <benefit-pdf-button></benefit-pdf-button>
            <button class="btn pull-right btn-one hidden-sm hidden-xs" @click="movePage('/claims-process')" :class="{btnleft: getLang == 'en'}">
              <router-link :to="'/claims-process'" class="uppercase" :lang="getLang">{{$t('lang.common.reportAnIncident')}}</router-link>
            </button>
          </div>
        </div>
      </div>
    </section>

    <div class="clearfix"></div>

    <section class="sec_common sec_2">
      <div class="container">
        <div class="row marsh_row text-center">
          <div class="col-md-3 col-md-offset-3 col-sm-6 col-3">
            <div class="column" :lang="getLang" v-html="$t('lang.liability.thePublicLiabilityUs_1_500_000')">
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-3">
            <div class="column" :lang="getLang" v-html="$t('lang.liability.whatYouHaveToUs_100')">
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="clearfix"></div>

    <section class="sec_common sec_3 sec_3_liability">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <h3 :lang="getLang">{{$t('lang.common.coversAllRidersWhenOffVehicle')}}</h3>
            <br>
          </div>

          <div class="col-md-10 col-md-offset-1 hidden-xs iconbox">
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-6 text-center">
                <div class="insurance-box bl-org">
                  <img src="@/assets/images/icons/icon_scooter.png">
                </div>
              </div>

              <div class="col-md-3 col-sm-3 col-xs-6 text-center">
                <div class="insurance-box bl-blue">
                  <img src="@/assets/images/icons/icon_car.png">
                </div>
              </div>

              <div class="col-md-3 col-sm-3 col-xs-6 text-center">
                <div class="insurance-box bl-pn">
                  <img src="@/assets/images/icons/icon_cycle.png">
                </div>
              </div>

              <div class="col-md-3 col-sm-3 col-xs-6 text-center">
                <div class="insurance-box bl-nv">
                  <img src="@/assets/images/icons/icon_foot.png">
                </div>
              </div>
            </div>
          </div>
          <!-- mobile carousel -->
          <div class="col-xs-12 hidden-sm hidden-md hidden-lg mobile-business">
            <carousel :loop="true" :autoplay="true" :perPage="1" :autoplayTimeout="4000">
              <slide
                  data-index="0"
                  data-name="icon_scooter">
                <img src="@/assets/images/icons/icon_scooter.png">
              </slide>
              <slide
                  data-index="1"
                  data-name="icon_car">
                <img src="@/assets/images/icons/icon_car.png">
              </slide>
              <slide
                  data-index="2"
                  data-name="icon_cycle">
                <img src="@/assets/images/icons/icon_cycle.png">
              </slide>
              <slide
                  data-index="3"
                  data-name="icon_foot">
                <img src="@/assets/images/icons/icon_foot.png">
              </slide>
            </carousel>
          </div>
          <!-- mobile carousel -->
        </div>
      </div>
    </section>

    <div class="clearfix"></div>

    <section class="sec_common sec_4">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-10" :lang="getLang" v-html="$t('lang.liability.ourUnderwriterChubb')">
          </div>
          <div class="col-md-2 col-sm-2">
            <img class="img-responsive pull-right" src="@/assets/images/chubb.png">
          </div>
        </div>
      </div>
    </section>

    <div class="clearfix"></div>

    <section class="sec_common sec_5">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-3 col-xs-4 coll-3 hidden-sm hidden-xs">
            <img class="img-responsive liability-bottom-image" src="@/assets/images/img_work_injury_footer.jpg" alt="">
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12 bottom-blue-box">
            <h2 :lang="getLang" :class="{boldText: getLang == 'zh'}">{{$t('lang.common.personalAccidentAndIncomeProtection')}}</h2>
            <p :lang="getLang">{{$t('lang.common.coversAllRidersWhenWorking')}}</p>
            <button class="btn" @click="movePage('/group-personal')">
              <router-link :to="'/group-personal'" :lang="getLang">{{$t('lang.common.findOutMore')}}</router-link>
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import benefitPdfButton from '@/components/hk/shared/benefit.pdf.button'
import { Carousel, Slide } from 'vue-carousel'
export default {
  components: {
    benefitPdfButton,
    Carousel,
    Slide
  },
  data () {
    return {}
  },
  props: [],
  methods: {
    movePage (path) {
      this.$router.push(path)
    }
  },
  computed: {
    getLang () {
      return this.$store.state.selectedLanguageCode === 'hk' || this.$store.state.selectedLanguageCode === 'tw' ? 'zh' : 'en'
    }
  },mounted(){
    this.$vuetify.goTo(0, {container: document.documentElement,duration:0})
  }
}
</script>

<style>
</style>
