<template>
  <v-container class="ma-0 pa-0" fluid style="background: #FFFFFF">
    <v-layout class="">
      <v-flex sm2></v-flex>
      <v-flex xs12 sm8>
        <div id="rider" style="background: #ffffff">

          <v-form v-model="validRider" ref="formrider" lazy-validation>

            <v-card
                v-if="IsPersonalDisabilityCoverageType || IsAccidentalDeathCoverageType"
                class="ma-0 pa-0"
                elevation="0"
                :tile=true>
              <v-layout row wrap justify-left px-1>
                <v-flex xs12 sm6 md4 px-4>
                  <v-list-item-title class="my-4">
                    Relationship to the rider <strong class="red--text">*</strong>
                  </v-list-item-title>
                  <v-select
                      name="relationship"
                      v-model="reporterRelationship"
                      outlined
                      dense
                      color="primary"
                      item-value="code"
                      item-text="value"
                      :items="getRelationshipByCoverageOptions"
                      :label="'Select'"
                      :single-line=true
                      :rules="[rules.required]"/>
                </v-flex>
              </v-layout>
            </v-card>
            <v-expand-transition>
              <v-card
                  v-if="IsShowReporterDetails"
                  class="ma-0 pa-0"
                  elevation="0"
                  :tile=true>
                <v-card
                    :tile=true
                    class="elevation-0 menu-divider">
                  <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
                    <v-toolbar-title class="pa-4 font-weight-bold section-title">Reporter Details</v-toolbar-title>
                    <v-btn
                        v-if="$vuetify.breakpoint.smAndDown"
                        icon
                        @click="show_reporter_details = !show_reporter_details"
                        style="right: auto">
                      <v-icon>{{ show_reporter_details ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                  </v-col>
                </v-card>
                <v-card class="elevation-0 px-2">
                  <v-expand-transition>
                    <div v-if="show_reporter_details">
                      <v-layout row wrap justify-left px-1>

                        <v-flex xs12 sm6 md4 px-4>
                          <v-list-item-title class="my-4">
                            Title <strong class="red--text">*</strong>
                          </v-list-item-title>
                          <v-select
                              name="reportertitle"
                              v-model="reporterInfo.title"
                              outlined
                              dense
                              color="primary"
                              item-value="code"
                              item-text="value"
                              :items="titleOptions"
                              :label="'Select'"
                              :single-line=true
                              :rules="[rules.required]"/>
                        </v-flex>

                        <v-flex xs12 sm6 md4 px-4>
                          <v-list-item-title class="my-4">
                            Email <strong class="red--text">*</strong>
                          </v-list-item-title>
                          <v-text-field
                              v-model="reporterInfo.email"
                              color="primary"
                              solo
                              outlined
                              flat
                              dense
                              :rules="[rules.required,rules.emailValidation]"
                              :validate-on-blur=true
                              maxlength="140"/>
                        </v-flex>

                        <v-flex xs12 sm6 md4 px-4>
                          <v-list-item-title class="my-4">
                            Contact Number <strong class="red--text">*</strong>
                          </v-list-item-title>
                          <v-text-field
                              v-model="reporterInfo.contactnumber"
                              color="primary"
                              solo
                              outlined
                              flat
                              dense
                              @keypress="isNumber($event)"
                              :rules="[rules.required,'Hong Kong'===$store.state.country? rules.hongKongNumberRule:rules.singaporeNumberRule,rules.numberrule]"
                              :prefix="('Hong Kong'===$store.state.country?'(+852)':'(+65)')"
                              :validate-on-blur=true
                              maxlength="8"
                          />
                        </v-flex>


                        <v-flex xs12 sm6 md4 px-4>
                          <v-list-item-title class="my-4">
                            First name <strong class="red--text">*</strong>
                          </v-list-item-title>
                          <v-text-field
                              v-model="reporterInfo.firstname"
                              color="primary"
                              solo
                              outlined
                              flat
                              dense
                              :rules="[rules.required]"
                              :validate-on-blur=true
                              maxlength="30"/>
                        </v-flex>

                        <v-flex xs12 sm6 md4 px-4>
                          <v-list-item-title class="my-4">
                            Middle name
                          </v-list-item-title>
                          <v-text-field
                              v-model="reporterInfo.middlename"
                              color="primary"
                              solo
                              outlined
                              flat
                              dense
                              maxlength="30"/>
                        </v-flex>

                        <v-flex xs12 sm6 md4 px-4>
                          <v-list-item-title class="my-4">
                            Last name <strong class="red--text">*</strong>
                          </v-list-item-title>
                          <v-text-field
                              v-model="reporterInfo.lastname"
                              color="primary"
                              solo
                              outlined
                              flat
                              dense
                              :rules="[rules.required]"
                              :validate-on-blur=true
                              maxlength="100"/>
                        </v-flex>

                        <v-flex xs12 sm6 md4 px-4>
                          <v-list-item-title class="my-4">
                            {{ getTitleForID }} <strong class="red--text">*</strong>
                          </v-list-item-title>
                          <v-text-field
                              v-model="reporterInfo.hongkongid"
                              color="primary"
                              solo
                              outlined
                              flat
                              dense
                              :placeholder=getIdContentHolder
                              :rules="[rules.required,'Hong Kong'===$store.state.country? HKIDverification(reporterInfo.hongkongid):SGIDverification(reporterInfo.hongkongid),rules.blockDuplicatedIDRule]"
                              :validate-on-blur=true
                              maxlength="9"
                          />
                        </v-flex>

                        <v-flex xs12 sm6 md4 px-4>
                          <v-list-item-title class="my-4">
                            Gender <strong class="red--text">*</strong>
                          </v-list-item-title>
                          <v-select
                              name="reportergender"
                              v-model="reporterInfo.gender"
                              outlined
                              dense
                              color="primary"
                              item-value="code"
                              item-text="value"
                              :items="genderOptions"
                              :label="'Select'"
                              :single-line=true
                              :rules="[rules.required]"/>
                        </v-flex>

                        <!-- Reporter Date Picker-->
                        <v-flex xs12 sm6 md4 px-4>
                          <v-list-item-title class="my-4">
                            Date of Birth(DD/MM/YY) <strong class="red--text">*</strong>
                          </v-list-item-title>

                          <v-menu
                              v-model="fromDateMenuReporter"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                          >
                            <template
                                v-slot:activator="{ on }">
                              <v-text-field
                                  v-on="on"
                                  outlined
                                  readonly
                                  dense
                                  prepend-icon="event"
                                  color="primary"
                                  placeholder="DD \ MM \ YY"
                                  :value="reporterInfo.dateofbirth|moment"
                                  :rules="[rules.required]"/>
                            </template>
                            <v-date-picker
                                v-model="reporterInfo.dateofbirth"
                                no-title
                                locale="en-in"
                                :max="new Date().toISOString().substr(0, 10)"
                                @input="fromDateMenuReporter = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-flex>

                        <v-flex xs12 sm6 md4 px-4>
                          <v-list-item-title class="my-4">
                            Country
                          </v-list-item-title>
                          <v-text-field
                              v-model="GetCountryName"
                              disabled
                              color="primary"
                              solo
                              outlined
                              flat
                              dense/>
                        </v-flex>

                        <v-flex xs12 sm6 md4 px-4>
                          <v-list-item-title class="my-4">
                            District
                          </v-list-item-title>
                          <v-text-field
                              v-model="reporterInfo.district"
                              color="primary"
                              solo
                              outlined
                              flat
                              dense
                          />
                        </v-flex>
                        <v-flex xs12 sm6 md4 px-4>
                          <v-list-item-title class="my-4">
                            Address 1 <strong class="red--text">*</strong>
                          </v-list-item-title>
                          <v-text-field
                              v-model="reporterInfo.address1"
                              color="primary"
                              solo
                              outlined
                              flat
                              dense
                              :rules="[rules.required,rules.address1Rule]"
                              :validate-on-blur=true
                              maxlength="140"/>
                        </v-flex>
                        <v-flex xs12 sm6 md4 px-4>
                          <v-list-item-title class="my-4">
                            Address 2
                          </v-list-item-title>
                          <v-text-field
                              v-model="reporterInfo.address2"
                              color="primary"
                              solo
                              outlined
                              flat
                              dense
                              :rules="[rules.address2Rule]"
                              :validate-on-blur=true
                              maxlength="140"/>
                        </v-flex>
                        <v-flex xs12 sm6 md4 px-4>
                          <v-list-item-title class="my-4">
                            Address 3
                          </v-list-item-title>
                          <v-text-field
                              v-model="reporterInfo.address3"
                              color="primary"
                              solo
                              outlined
                              flat
                              dense
                              :rules="[rules.address3Rule]"
                              :validate-on-blur=true
                              maxlength="140"
                          />
                        </v-flex>
                        <v-flex xs12 sm6 md4 px-4>
                          <v-list-item-title class="my-4" v-if="GetCountryName==='Singapore'">
                            Postal Code <strong class="red--text">*</strong>
                          </v-list-item-title>
                          <v-list-item-title class="my-4" v-if="GetCountryName=='Hong Kong'">
                            Address 4
                          </v-list-item-title>
                          <v-text-field
                              v-model="reporterInfo.address4"
                              color="primary"
                              solo
                              outlined
                              flat
                              dense
                              @keypress="GetCountryName==='Singapore'?isNumber($event):''"
                              :rules="GetCountryName==='Singapore'?[rules.required,rules.postalCodeRule,rules.numberrule]:[rules.address4Rule]"
                              :validate-on-blur=true
                              :maxlength="GetCountryName==='Singapore'?6:140"
                          />
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-expand-transition>
                </v-card>
              </v-card>
            </v-expand-transition>
            <v-card elevation="0">
              <v-card
                  :tile=true
                  class="ma-0 pa-0 menu-divider"
                  elevation="0">
                <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
                  <v-toolbar-title class="pa-4 font-weight-bold section-title">Rider Details</v-toolbar-title>
                  <v-btn
                      v-if="$vuetify.breakpoint.smAndDown"
                      icon
                      @click="show_rider_details = !show_rider_details"
                      style="right: auto">
                    <v-icon>{{ show_rider_details ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-col>
              </v-card>

              <v-card class="elevation-0 px-2">
                <v-expand-transition>
                  <div v-if="show_rider_details">
                    <v-layout row wrap justify-left px-1>

                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4">
                          Title <strong class="red--text">*</strong>
                        </v-list-item-title>
                        <v-select
                            name="ridertitle"
                            v-model="riderInfo.title"
                            outlined
                            dense
                            color="primary"
                            item-value="code"
                            item-text="value"
                            :items="titleOptions"
                            :label="'Select'"
                            :single-line=true
                            :rules="[rules.required]"/>
                      </v-flex>

                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4">
                          Type of Rider <strong class="red--text">*</strong>
                        </v-list-item-title>
                        <v-select
                            name="ridertypeofrider"
                            v-model="riderInfo.typeofrider"
                            outlined
                            dense
                            color="primary"
                            item-value="code"
                            item-text="value"
                            :items="typeofRiderOptions"
                            :label="'Select'"
                            :single-line=true
                            :rules="[rules.required]"/>
                      </v-flex>

                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4">
                          Transportation mode <strong class="red--text">*</strong>
                        </v-list-item-title>
                        <v-select
                            name="reportertypeofrider"
                            v-model="riderInfo.transportationmode"
                            outlined
                            dense
                            color="primary"
                            item-value="code"
                            item-text="value"
                            :items="transportationModeOptions"
                            :label="'Select'"
                            :single-line=true
                            :rules="[rules.required]"/>
                      </v-flex>

                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4">
                          First name <strong class="red--text">*</strong>
                        </v-list-item-title>
                        <v-text-field
                            v-model="riderInfo.firstname"
                            color="primary"
                            solo
                            outlined
                            flat
                            dense
                            :rules="[rules.required]"
                            :validate-on-blur=true
                            maxlength="30"/>
                      </v-flex>

                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4">
                          Middle name
                        </v-list-item-title>
                        <v-text-field
                            v-model="riderInfo.middlename"
                            color="primary"
                            solo
                            outlined
                            flat
                            dense
                            :validate-on-blur=true
                            maxlength="30"/>
                      </v-flex>

                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4">
                          Last name <strong class="red--text">*</strong>
                        </v-list-item-title>
                        <v-text-field
                            v-model="riderInfo.lastname"
                            color="primary"
                            solo
                            outlined
                            flat
                            dense
                            :rules="[rules.required]"
                            :validate-on-blur=true
                            maxlength="100"/>
                      </v-flex>

                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4">
                          {{ getTitleForID }} <strong class="red--text">*</strong>
                        </v-list-item-title>
                        <v-text-field
                            v-model="riderInfo.hongkongid"
                            color="primary"
                            solo
                            outlined
                            flat
                            dense
                            :placeholder=getIdContentHolder
                            :rules="[rules.required,rules.blockDuplicatedIDRule,
                            'Hong Kong'===$store.state.country? HKIDverification(riderInfo.hongkongid):SGIDverification(riderInfo.hongkongid)]"
                            :validate-on-blur=true
                            maxlength="9"/>
                      </v-flex>

                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4">
                          Gender <strong class="red--text">*</strong>
                        </v-list-item-title>
                        <v-select
                            name="riderInfoGender"
                            v-model="riderInfo.gender"
                            outlined
                            dense
                            color="primary"
                            item-value="code"
                            item-text="value"
                            :items="genderOptions"
                            :label="'Select'"
                            :single-line=true
                            :rules="[rules.required]"/>
                      </v-flex>

                      <!-- Rider Date Picker-->
                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4">
                          Date of Birth(DD/MM/YY) <strong class="red--text">*</strong>
                        </v-list-item-title>

                        <v-menu
                            v-model="fromDateMenuRider"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                          <template
                              v-slot:activator="{ on }">
                            <v-text-field
                                v-on="on"
                                outlined
                                readonly
                                dense
                                prepend-icon="event"
                                color="primary"
                                placeholder="DD \ MM \ YY"
                                :value="riderInfo.dateofbirth|moment"
                                :rules="[rules.required]">
                            </v-text-field>
                          </template>
                          <v-date-picker
                              v-model="riderInfo.dateofbirth"
                              no-title
                              locale="en-in"
                              :max="new Date().toISOString().substr(0, 10)"
                              @input="fromDateMenuRider = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>

                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4">
                          Rider ID# <strong class="red--text">*</strong>
                        </v-list-item-title>
                        <v-text-field
                            v-model="riderInfo.deliverooid"
                            color="primary"
                            solo
                            outlined
                            flat
                            dense
                            @keypress="isNumber($event)"
                            :rules="[rules.required,rules.numberrule,rules.deliverooIdRule]"
                            :validate-on-blur=true
                            maxlength="6"/>
                      </v-flex>

                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4">
                          Email <strong class="red--text">*</strong>
                        </v-list-item-title>
                        <v-text-field
                            v-model="riderInfo.email"
                            color="primary"
                            solo
                            outlined
                            flat
                            dense
                            :validate-on-blur=true
                            :rules="[rules.emailValidation,rules.required]"
                            maxlength="140"/>
                      </v-flex>
                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4">
                          Contact Number <strong class="red--text">*</strong>
                        </v-list-item-title>
                        <v-text-field
                            v-model="riderInfo.contactnumber"
                            color="primary"
                            solo
                            outlined
                            flat
                            dense
                            @keypress="isNumber($event)"
                            :rules="[rules.required,'Hong Kong'===$store.state.country? rules.hongKongNumberRule:rules.singaporeNumberRule,rules.numberrule]"
                            :validate-on-blur=true
                            maxlength="8"
                            :prefix="('Hong Kong'===$store.state.country?'(+852)':'(+65)')"/>
                      </v-flex>

                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4">
                          Country
                        </v-list-item-title>
                        <v-text-field
                            v-model="GetCountryName"
                            disabled
                            color="primary"
                            solo
                            outlined
                            flat
                            dense></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4">
                          District
                        </v-list-item-title>
                        <v-text-field
                            v-model="riderInfo.district"
                            color="primary"
                            solo
                            outlined
                            flat
                            dense/>
                      </v-flex>
                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4">
                          Address 1 <strong class="red--text">*</strong>
                        </v-list-item-title>
                        <v-text-field
                            v-model="riderInfo.address1"
                            color="primary"
                            solo
                            outlined
                            flat
                            dense
                            :rules="[rules.required,rules.address1Rule]"
                            :validate-on-blur=true
                            maxlength="140"
                        />
                      </v-flex>
                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4">
                          Address 2
                        </v-list-item-title>
                        <v-text-field
                            v-model="riderInfo.address2"
                            color="primary"
                            solo
                            outlined
                            flat
                            dense
                            :rules="[rules.address2Rule]"
                            :validate-on-blur=true
                            maxlength="140"
                        />
                      </v-flex>
                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4">
                          Address 3
                        </v-list-item-title>
                        <v-text-field
                            v-model="riderInfo.address3"
                            color="primary"
                            solo
                            outlined
                            flat
                            dense
                            :rules="[rules.address3Rule]"
                            :validate-on-blur=true
                            maxlength="140"
                        />
                      </v-flex>
                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4" v-if="GetCountryName==='Singapore'">
                          Postal Code <strong class="red--text">*</strong>
                        </v-list-item-title>
                        <v-list-item-title class="my-4" v-if="GetCountryName=='Hong Kong'">
                          Address 4
                        </v-list-item-title>
                        <v-text-field
                            v-model="riderInfo.address4"
                            color="primary"
                            solo
                            outlined
                            flat
                            dense
                            @keypress="GetCountryName==='Singapore'?isNumber($event):''"
                            :rules="GetCountryName==='Singapore'?[rules.required,rules.postalCodeRule,rules.numberrule]:[rules.address4Rule]"
                            :validate-on-blur=true
                            :maxlength="GetCountryName==='Singapore'?6:140"
                        />
                      </v-flex>
<!--                      <v-flex xs12 sm6 md4 px-4>-->
<!--                        <v-list-item-title class="my-4">-->
<!--                          Preferred method contact-->
<!--                        </v-list-item-title>-->
<!--                        <v-radio-group-->
<!--                            v-model="riderInfo.preferedcontact"-->
<!--                            row-->
<!--                            class="ma-0 pa-0">-->
<!--                          <v-radio-->
<!--                              v-for="radio in prefferedContactOptions"-->
<!--                              v-bind:key="radio.code"-->
<!--                              :label="radio.value"-->
<!--                              :value="radio.code"-->
<!--                              color="#00CCBC"/>-->
<!--                        </v-radio-group>-->

<!--                      </v-flex>-->
                      <v-flex xs12 sm12 v-if="!this.$route.query.modify">
                        <v-btn
                            x-large
                            depressed
                            block
                            :tile=true
                            class="ma-0 pa-0 white--text d-md-flex d-lg-none action-button"
                            color="#00CCBC"
                            @click="validateAndGoNext">Next
                        </v-btn>
                      </v-flex>

                      <v-flex xs12 sm12 v-if="this.$route.query.modify">
                        <v-btn
                            x-large
                            depressed
                            block
                            :tile=true
                            class="ma-0 pa-0 white--text d-md-flex d-lg-none action-button"
                            color="#00CCBC"
                            @click="validateAndApplyChange">
                          Apply Change
                          <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
                        </v-btn>
                      </v-flex>
                      <v-flex xs12 sm12 v-if="this.$route.query.modify">
                        <v-btn
                            x-large
                            depressed
                            block
                            :tile=true
                            class="ma-0 pa-0 white--text d-md-flex d-lg-none action-button"
                            color="#93A1B4"
                            @click="goPrevious">Cancel Change
                          <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
                        </v-btn>
                      </v-flex>

                    </v-layout>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-card>
            <!-- fab icon -->
            <v-btn
                v-scroll="onScroll"
                v-show="is_show_fav_icon && $vuetify.breakpoint.smAndDown"
                elevation="1"
                fab
                fixed
                bottom
                right
                color="blue darken-3"
                style="margin-bottom: 60px;margin-right: 16px;"
                @click="toTop">
              <img :src="icons.bottom_top_arrow" @click="toTop" style="max-width: 20px; max-height: 20px; ">
            </v-btn>
          </v-form>
        </div>
      </v-flex>
      <v-flex sm2>

      </v-flex>
    </v-layout>
    <v-layout class="d-none d-lg-flex mb-8 " :tile="true">
      <v-flex lg2></v-flex>
      <v-flex xs12 lg8 :class="$vuetify.breakpoint.mdAndUp?'menu-divider-top':''" v-if="this.$route.query.modify">
        <v-row>
          <v-col offset="3" cols="3" class=" pr-2">
            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#93A1B4"
                class="white--text action-button"
                @click="goPrevious()"
            >
              <v-icon>{{ 'mdi-chevron-left' }}</v-icon>
              Cancel Change
            </v-btn>
          </v-col>
          <v-col cols="3" class="pl-2">
            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#00CCBC"
                class="white--text action-button"
                style="text-align: right;"
                @click="validateAndApplyChange"
            >Apply Change
              <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>
      <v-flex xs12 lg8 :class="$vuetify.breakpoint.mdAndUp?'menu-divider-top':''" v-if="!this.$route.query.modify">
        <v-row>
          <v-col offset="3" cols="3" class=" pr-2">
            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#93A1B4"
                class="white--text action-button"
                @click="goPrevious()"
            >
              <v-icon>{{ 'mdi-chevron-left' }}</v-icon>
              Previous

            </v-btn>
          </v-col>
          <v-col cols="3" class="pl-2">

            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#00CCBC"
                class="white--text action-button"
                style="text-align: right;"
                @click="validateAndGoNext"
            >Next
              <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from "moment";
import {mapGetters, mapMutations} from "vuex";

export default {
  data() {
    return {
      show_reporter_details: true,
      show_rider_details: true,
      is_show_fav_icon: true,
      icons: {
        personal_accident_icon: require('@/assets/icons/icon_Image_00.png'),
        public_liability_icon: require('@/assets/icons/icon_Image_01.png'),
        right_arrow_icon: require('@/assets/icons/icon_select_arrow_01.png'),
        bottom_top_arrow: require('@/assets/icons/bottom_top_arrow.png'),
        date_icon: require('@/assets/icons/icon_Image_09.png'),
      },
      minDate: "1930-01-01",
      maxDate: "2000-12-31",
      pickerDate: "1995-01-01",
      fromDateMenuRider: false,
      fromDateMenuReporter: false,

      validRider: false,

      riderInfo: this.getRiderInfo(),
      reporterInfo: this.getReporterInfo(),
      reporterRelationship: this.getReporterRelationshipInfo(),

      rules: {
        required: value => (!!value && value.trim().length!==0) || "This field is mandatory",
        numberrule: value => {
          // const pattern = /^\d*\.?\d*$/;
          const pattern =/^\d+$/;

          if (value == null || value == undefined) {
            return true;
          }
          return (
              pattern.test(
                  String(value)
                      .replace(/\,/g, "")
                      .replace(/\-/g, "")
              ) || "This field must have numeric value"
          );
        },
        address1Rule: v => v.length <= 140 || 'Please enter valid Address 1',
        address2Rule: v => v.length <= 140 || 'Please enter valid Address 2',
        address3Rule: v => v.length <= 140 || 'Please enter valid Address 3',
        address4Rule: v => v.length <= 140 || 'Please enter valid Address 4',
        postalCodeRule: v => v.length === 6 || 'Please enter valid Postal Code',
        deliverooIdRule: v => (v.length < 7&&v!=='0') || 'Please enter a valid Rider ID#',
        singaporeNumberRule:
            v => ((v.length === 8 && (v.startsWith(8) || v.startsWith(9))) || (v.length === 0)) || 'Please enter a valid contact number'
        ,
        hongKongNumberRule:
            v => (v.length === 8 || (v.length === 0)) || 'Please enter a valid contact number'
        ,
        blockDuplicatedIDRule:
            v => (this.reporterInfo.hongkongid.toUpperCase()!==this.riderInfo.hongkongid.toUpperCase()) || 'Please enter different ID.'
        ,
        emailValidation: value => {
          var pattern = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
          if (value == null || value === undefined || value === '') {
            return true;
          }
          return (
              pattern.test(
                  value
              ) || "E-mail must be valid"
          );
        },
      },
      HKIDBasicInfo: {
        "A": "issued between 1949 and 1962.",
        "B": "issued in the urban office between 1955 and 1960..",
        "C": "issued at the New Territories Office between 1960 and 1983.",
        "D": "issued at the Hong Kong Island Office between 1960 and 1983.",
        "E": "issued at the Kowloon Office between 1955 and 1969.",
        "F": "issued at the New Territories Office between 1979 and 1983.",
        "G": "issued at the Kowloon Office between 1967 and 1983.",
        "H": "issued at the Hong Kong Island Office between 1979 and 1983.",
        "J": "issued for consulate employee.",
        "K": "issued for persons who first registered their ID cards from March 28, 1983 to July 31, 1990.",
        "L": "An alternate number issued between 1983 and 1999 for the failure of a computer system.",
        "M": "issued for persons who registered their ID cards for the first time since August 1, 2011.",
        "N": "Deprecated. For now, there are only one person with this character.",
        "P": "issued for persons who first registered their identity cards from August 1, 1990 to December 27, 2000.",
        "R": "issued for persons who registered their identity cards for the first time from December 28, 2000 to July 31, 2011.",
        "S": "issued for persons registered in Hong Kong from April 1, 2005.",
        "T": "An alternate number issued between 1983 and 1999 for the failure of a computer system.",
        "V": "issued for persons who was issued a Document of Identity for Visa Purposes before the age of 11.",
        "W": "issued for foreign workers and foreign domestic helpers.",
        "WX": "issued for foreign workers and foreign domestic helpers.",
        "X": "issued for persons who have new registration ID without Chinese name.",
        "XA": "issued for persons who registered a new ID without Chinese name.",
        "XB": "issued for persons who registered a new ID without Chinese name.",
        "XC": "issued for persons who registered a new ID without Chinese name.",
        "XD": "issued for persons who registered a new ID without Chinese name.",
        "XE": "issued for persons who registered a new ID without Chinese name.",
        "XG": "issued for persons who registered a new ID without Chinese name.",
        "Y": "issued for persons registered in Hong Kong from January 1, 1989 to March 2005.",
        "Z": "issued for persons registered in Hong Kong from January 1, 1980 to December 31, 1988.",
        "default": "not in our database."
      }
    };
  },
  computed: {
    IsAMERCoverageType() {
      return this.$store.state.incidenttype2 === "AMER";
    },
    IsPersonalDisabilityCoverageType() {
      return this.$store.state.incidenttype2 === "PD";
    },
    IsPersonalAccidentCoverageType() {
      return this.$store.state.incidenttype2 === "LDPA";
    },
    IsAccidentalDeathCoverageType() {
      return this.$store.state.incidenttype2 === "AD";
    },
    GetCountryName() {
      return this.$store.state.country;
    },
    getTitleForID() {
      if (this.$store.state.country === 'Singapore') {
        return 'National Registration ID card'
      } else {
        return 'Hong Kong ID number'
      }
    },
    getIdContentHolder() {
      if (this.$store.state.country === 'Singapore') {
        return 'e.g. 1234567'
      } else {
        return 'e.g. X123456(A), please input as X123456A'
      }
    },
    IsShowReporterDetails() {
      return this.reporterRelationship === 'SPO' || this.reporterRelationship === 'PAR' || this.reporterRelationship === 'CHI'
    },
    getRelationshipByCoverageOptions() {
      return this.IsAccidentalDeathCoverageType ? this.relationOptions.filter(r => r.code !== 'MYS') : this.relationOptions
    },
    ...mapGetters([
      'getReporterRelationship',
      'titleOptions',
      'relationOptions',
      'genderOptions',
      'prefferedContactOptions',
      'typeofRiderOptions',
      'transportationModeOptions',

      'reporterDetails',
      'riderDetails'
    ]),
  },
  methods: {
    ...mapMutations(['saveReporterDetails', 'saveRiderDetails']),
    assertMaxChars: function (value, maxLengthInCars) {
      if (value.length >= maxLengthInCars) {
        value = value.substring(0, maxLengthInCars);
      }
    },

    initPageTitle: function () {
      if (this.$route.query.modify) {
        this.$store.commit('changeMenuTitle', 'Modify Claim')
      } else {
        this.$store.commit('changeMenuTitle', 'Make a Claim')
      }

    },
    checkShowClaimProgressBar: function () {
      if (this.$route.query.modify) {
        this.$store.commit('changeShowClaimProgressBarStatus', false)
      } else {
        this.$store.commit('changeShowClaimProgressBarStatus', true)
      }

    },
    initSubMenuTitle: function () {
      this.$store.commit('changeSubMenuTitle', 'Fill Out Claim Form')
    },
    initTopProgressStep: function () {
      this.$store.commit('changeStep', 2)
    },
    getReporterRelationshipInfo() {
      return _.cloneDeep(this.$store.getters.getReporterRelationship)
    },
    getReporterInfo() {
      return _.cloneDeep(this.$store.getters.reporterDetails)
    },
    getRiderInfo() {
      return _.cloneDeep(this.$store.getters.riderDetails)
    },
    validateAndGoNext: function () {
      if (this.$refs.formrider.validate()) {
        this.$store.commit('saveReporterDetails', this.reporterInfo);
        this.$store.commit('setReporterRelationship', this.reporterRelationship);
        this.$store.commit('saveRiderDetails', this.riderInfo);
        this.$router.push('/claims-incidentdetails');
      }else {
        let context = this;
        setTimeout(function () {
            let test = context.$el.querySelector('.v-messages__message')
            if (test){

              context.$vuetify.goTo(context.$el.querySelector('.v-messages__message').parentNode.parentNode.parentNode.parentNode, {container: document.documentElement,duration:100})
              context.$el.querySelector('.v-messages__message').parentNode.parentNode.parentNode.parentNode.focus()
            }
        },100)
      }
    },
    validateAndApplyChange: function () {
      if (this.$refs.formrider.validate()) {
        this.$store.commit('saveReporterDetails', this.reporterInfo);
        this.$store.commit('saveRiderDetails', this.riderInfo);
        this.$store.commit('setReporterRelationship', this.reporterRelationship);
        this.$router.back()
      }
      else {
        let context = this;
        setTimeout(function () {
          let test = context.$el.querySelector('.v-messages__message')
          if (test){

            context.$vuetify.goTo(context.$el.querySelector('.v-messages__message').parentNode.parentNode.parentNode.parentNode, {container: document.documentElement,duration:100})
            context.$el.querySelector('.v-messages__message').parentNode.parentNode.parentNode.parentNode.focus()
          }
        },100)
      }
    },
    toTop() {
      this.$vuetify.goTo(0, {container: document.documentElement})
    },
    onScroll(e) {
      if (typeof window === 'undefined') {
        return
      }
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.is_show_fav_icon = top > 10
    },
    initFormFields: function () {
      if (this.$store.state.country === 'Singapore') {
        //commit
        this.$store.commit('saveRiderCountry', 'SG')
      } else {
        this.$store.commit('saveRiderCountry', 'HK')
      }
    },
    goPrevious() {
      this.$router.back()
    },
    HKIDverification(id) {
      ///Pre-Operation///
      //Check if the value inputted by user is a vaild and if the value consist 8 character, do the following
      id = id.toUpperCase()
      let letterASCII, productNsum, converted_1, converted_2, converted, remainder, check, check_2, letterASCII_1,
          letterASCII_2;
      var errorMessage = 'This is not a valid Hong Kong ID number';

      var reg = /^[A-Z]{1,2}[0-9]{6}[0-9A-F]{1}/
      if (reg.exec(id)===null){
        return errorMessage
      }

      if (id.length == 8) {
        //Convert character to ASCII code
        letterASCII = id[0].charCodeAt();
        //Check if the value inputted by user is a vaild
        if (letterASCII >= 65 && letterASCII <= 90 || letterASCII >= 97 && letterASCII <= 122) {
          //Convert lower case letter to upper case
          if (letterASCII >= 97 && letterASCII <= 122) {
            //Set character to upper case with ASCII code
            letterASCII = letterASCII - 32;
          }
          //Convert letter to number for later on calculation by ASCII - 64
          converted = letterASCII - 64;
          ///Operation///
          //Calculate product and sum of user inputted ID Card number
          productNsum = converted * 8 + parseInt(id[1]) * 7 + parseInt(id[2]) * 6 + parseInt(id[3]) * 5 + parseInt(id[4]) * 4 + parseInt(id[5]) * 3 + parseInt(id[6]) * 2;
          //Find remainder of calculated product and sum
          remainder = productNsum % 11;
          //Default check digit is zero
          check = 0;
          check_2 = 0; //In case the user enter a lower case HKID Card number
          //If remainder is not zero, run the following code
          if (remainder != 0) {
            //Check digit is 11 - remainder
            check = 11 - remainder;
            check_2 = check;
            //If check digit is 10, show "A" as check digit
            if (check == 10) {
              check = "A";
              check_2 = "a";
            }
          }
          //Send back the result to user
          if (id[7] == check || id[7] == check_2) {
            //Set default value for basic information
            var type = this.HKIDBasicInfo["default"];
            //Check if user input exist in dictionary
            if (id[0] in this.HKIDBasicInfo) {
              //Set basic information value
              type = this.HKIDBasicInfo[id[0]];
            }
            return true;
          } else {
            return errorMessage;
          }
        } else {
          return errorMessage;//In case, user did not provide a complete ID Card number
        }
        //Check if the value inputted by user is a vaild and if the value consist 9 character, do the following
      } else if (id.length == 9) {
        //Convert character to ASCII code
        letterASCII_1 = id[0].charCodeAt();
        letterASCII_2 = id[1].charCodeAt();
        //Check if the value inputted by user is a vaild
        if ((letterASCII_1 >= 65 && letterASCII_1 <= 90 || letterASCII_1 >= 97 && letterASCII_1 <= 122) && (letterASCII_2 >= 65 && letterASCII_2 <= 90 || letterASCII_2 >= 97 && letterASCII_2 <= 122)) {
          //Convert lower case letter to upper case
          if ((letterASCII_1 >= 97 && letterASCII_1 <= 122) || (letterASCII_2 >= 97 && letterASCII_2 <= 122)) {
            //Set character to upper case with ASCII code
            letterASCII_1 = letterASCII_1 - 32;
            letterASCII_2 = letterASCII_2 - 32;
          }
          //Convert letter to number for later on calculation by ASCII - 64
          converted_1 = letterASCII_1 - 55;
          converted_2 = letterASCII_2 - 55;
          //Calculate product and sum of user inputted ID Card number
          productNsum = converted_1 * 9 + converted_2 * 8 + parseInt(id[2]) * 7 + parseInt(id[3]) * 6 + parseInt(id[4]) * 5 + parseInt(id[5]) * 4 + parseInt(id[6]) * 3 + parseInt(id[7]) * 2;
          //Find remainder of calculated product and sum
          remainder = productNsum % 11;
          //Default check digit is zero
          check = 0;
          check_2 = 0; //In case the user enter a lower case HKID Card number
          //If remainder is not zero, run the following code
          if (remainder != 0) {
            //Check digit is 11 - remainder
            check = 11 - remainder;
            //If check digit is 10, show "A" as check digit
            if (check == 10) {
              check = "A";
              check_2 = "a";
            }
          }
          //Send back the result to user
          if (id[8] == check || id[8] == check_2) {
            //Set default value for basic information
            var type = this.HKIDBasicInfo["default"];
            //Check if user input exist in dictionary

            if ((id[0] + id[1]) in this.HKIDBasicInfo) {

              //Set basic information value

              type = this.HKIDBasicInfo[id[0] + id[1]];

            }
          }
          return true;

        } else {

          return errorMessage;

        }
      } else {
        return errorMessage;//In case, user did not provide a complete ID Card number
      }

    },
    SGIDverification(id) {
      var errorMessage = 'This is not a valid National Registration ID card';
      if (id.length != 9)
        return errorMessage;
      id = id.toUpperCase();
      var i,
          icArray = [];
      for (i = 0; i < 9; i++) {
        icArray[i] = id.charAt(i);
      }

      icArray[1] = parseInt(icArray[1], 10) * 2;
      icArray[2] = parseInt(icArray[2], 10) * 7;
      icArray[3] = parseInt(icArray[3], 10) * 6;
      icArray[4] = parseInt(icArray[4], 10) * 5;
      icArray[5] = parseInt(icArray[5], 10) * 4;
      icArray[6] = parseInt(icArray[6], 10) * 3;
      icArray[7] = parseInt(icArray[7], 10) * 2;

      var weight = 0;
      for (i = 1; i < 8; i++) {
        weight += icArray[i];
      }

      var offset = (icArray[0] == "T" || icArray[0] == "G") ? 4 : 0;
      var temp = (offset + weight) % 11;
      var st = ["J", "Z", "I", "H", "G", "F", "E", "D", "C", "B", "A"];
      var fg = ["X", "W", "U", "T", "R", "Q", "P", "N", "M", "L", "K"];
      var theAlpha;
      if (icArray[0] == "S" || icArray[0] == "T") {
        theAlpha = st[temp];
      } else if (icArray[0] == "F" || icArray[0] == "G") {
        theAlpha = fg[temp];
      }
      var val = (icArray[8] === theAlpha);
      if (val) {
        return true;
      } else {
        return errorMessage;
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.initPageTitle()
    this.checkShowClaimProgressBar()
    this.initSubMenuTitle()
    this.initTopProgressStep()
    this.initFormFields()
    this.$vuetify.goTo(0, {container: document.documentElement,duration:0})
  },
  filters: {
    moment: function (date) {
      var result = moment(date).format('DD/MM/YY')
      if (result === 'Invalid date') {
        return ''
      } else {
        return moment(date).format('DD/MM/YY')
      }
      // return moment(date).format('DD/MM/YY')
    }
  }
  ,
}
;
</script>
<style scoped>
* {
  text-transform: none !important;
}

.menu-divider {
  border-bottom: #47494E 1px solid;
}

.menu-divider-top {
  border-top: #47494E 1px solid;
  padding-top: 20px;
}

.action-button {
  text-transform: none !important;
  font-size: 20px;
}

.section-title {
  color: #37424A;
  font-size: 24px;
}
</style>
