<template>
  <v-app-bar
      app
      flat
      absolute
      color="#00A8C8"
      dense>
    <v-img
        v-show="$route.name!=='ClaimsRegistration' && !this.$route.query.modify"
        class="mx-2 d-lg-none"
        max-height="24"
        max-width="24"
        contain
        :src="icons.prev"
        @click="goPreviousPage"/>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>

    <v-layout column class="text-center white--text" style="line-height: 0.8rem; min-width: 310px">
      <v-toolbar-title
          v-text="this.$store.state.menu_title"
          class="font-weight-bold text-md-h5 text-h6 "/>
      <span
          style="white-space: nowrap;text-overflow: ellipsis;overflow:hidden"
          v-if="this.getCoverageText!==''"
          v-text="this.getCoverageText"/>
    </v-layout>
    <v-spacer/>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>

    <v-dialog
        v-model="quitDialog"
        persistent
        :width="$vuetify.breakpoint.smAndDown?'90%':429"
        v-if="!this.$route.query.modify"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-img :src="icons.cancel"
               class="mx-2 d-lg-none"
               max-width="24"
               max-height="20"
               contain v-on="on" v-bind="attrs"/>
        <v-card-text
            v-on="on" v-bind="attrs"
            class=" white--text d-none d-lg-inline-flex font-weight-bold body-2"
            style="width: 100px;
                        cursor:pointer">Cancel
        </v-card-text>
      </template>
      <v-card
          color="white"
          class="pt-4 ma-0"
          style="box-shadow: 3px 4px 5px #00000033;border: 2px solid #006D9E !important;border-radius: 10px; padding: 2px"
      >
        <v-icon style="float: right;padding-right: 16px" @click="quitDialog=!quitDialog">clear</v-icon>
        <v-card-title class="px-4 py-8 text-body-1 black--text" style="text-align: center">Are you sure you want to
          quit?
        </v-card-title>
        <v-card-text class="px-4 text-body-2 black--text">If you choose to cancel your claim application you will lose
          your progress and have to start again.
          Are you sure you want to cancel?
        </v-card-text>
        <div style="background: #F2FBFC 0% 0% no-repeat padding-box; height: 77px; padding-top: 5px !important;">
          <v-row justify="center" align-content="center" class="ma-0 pa-0">
            <v-col cols="6" align-self="center">
              <v-btn
                  color="#93A1B4"
                  class="ma-0  white--text text-body-1"
                  large
                  block
                  @click="quitDialog=!quitDialog">
                Cancel
              </v-btn>
            </v-col>
            <v-col cols="6" align-self="center">
              <v-btn
                  color="#00CCBC"
                  class="ma-0 white--text text-body-1"
                  large
                  block
                  @click="quitDialog=false;quit()">
                Ok
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
  </v-app-bar>
</template>

<script>
export default {
  name: "topBar",
  data() {
    return {
      drawer: true,
      quitDialog: false,
      icons: {
        cancel: require("@/assets/icons/top_close.png"),
        prev: require("@/assets/icons/top_prev.png"),
        prev_background: require("@/assets/icons/top_prev_bg.png")
      },
    };
  },
  methods: {
    goPreviousPage: function () {
      window.history.go(-1)
    },
    quit: function () {
      this.$router.push('/claims-process')
      this.resetFormData();
    },
    resetFormData: function () {
      this.$store.commit("resetFormData");
    }
  },
  computed: {
    getCoverageText() {
      return this.$store.getters.incidentType2Options ?
      this.$store.getters.incidentType2Options
          .filter(incidentType => incidentType.code === this.$store.getters.coverageType)
          .map(coverage => coverage.value).toString():''
    }
  }
};
</script>
