<template>
  <v-container fluid style="background: #F4F4F4;" class="pb-0">
    <v-layout class="d-md-flex d-lg-none" >
      <v-flex>
        <v-row class="mt-6" justify="space-between" >
          <v-col cols="12">
            <v-row align="center" justify="center">
              <v-img src="@/assets/images/icons/icon_Image_07.png" height="70" contain/>
            </v-row>
            <v-row align="center" justify="center">
              <v-card-title class="font-weight-bold text-h5 text-xs-center" style="color: #37424A">Important Information
              </v-card-title>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-card class="content-item" elevation="0">
              <v-row align="center" justify="center">
                <v-card flat
                        class="ma-6 mt-2">
                  <v-card-subtitle class="text-subtitle-2 font-weight-bold" style="color: #37424A">
                    How the data you supply is used:
                  </v-card-subtitle>
                  <v-card-subtitle class="text-body-2 pt-0" style="color: #37424A">
                    By submitting a claim, you are consenting Marsh, our insurers and Deliveroo the use of your data for the purposes of processing your claim in accordance with our data protection and <a :href="openPrivacyPolicy" target="_blank" style="color: #00A8C8">Privacy Policy.</a>
                  </v-card-subtitle>
                  <v-card-subtitle class="text-subtitle-2 font-weight-bold pt-2" style="color: #37424A">Please prepare the following documents and information
                    before starting the application:
                  </v-card-subtitle>
                  <v-card-text>
                    <ul style="color: #00A8C8;">
                      <li>Your Rider ID</li>
                      <li>{{ this.getAgreementIdContent }}</li>
                      <li>Rider Information</li>
                      <li>Medical Receipts, Medical Reports and any other documents
                        related to your injury
                      </li>
                    </ul>
                  </v-card-text>
                  <v-card-subtitle class="text-body-2 pt-0 font-weight-bold" style="color: #37424A">It is important for you to support and cooperate in the claim process and comply with the following requirements.</v-card-subtitle>
                  <v-card-text class="text-body-2" style="color: #37424A">
                    <ul style="list-style-type: decimal">
                      <li>All claims must be reported to the claims administrator as soon as possible, and within 30 days of the accident. If a claim cannot be reported within 30 days, you must provide a valid reason for the delay.</li>
                      <li>You must complete the claim form and provide any information and assistance which we may require in establishing the amount of any payment or settlement due.</li>
                      <li>You must not discuss or accept liability or responsibility to third parties verbally or in writing. You should simply direct them to us and we will handle matters for you.</li>
                    </ul>
                  </v-card-text>
                  <v-card-text class="text-body-2 my-0 py-0" style="color: #37424A">
                    Please note that if requirements are not adhered to, it could affect our ability to fully assess the claim or manage the costs of claims. Therefore, we may not be able to admit the claim or  payment could be reduced.
                  </v-card-text>
                </v-card>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" class="pa-0 mt-4 mb-0">
            <v-btn x-large depressed block :to="'/claim-coverage'"
                   color="#00CCBC"
                   class="white--text"
                   style="text-transform: none !important;"
                   :tile="true"
            >Start Application
              <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>

      </v-flex>
    </v-layout>

    <v-layout class="d-none d-lg-flex">
      <v-flex lg2></v-flex>
      <v-flex xs12 lg8 class=" pa-0 pa-lg-8">

        <v-card class="content-item pa-7" elevation="0" >
          <v-col cols="12">
            <v-row align="center" justify="center">
              <v-img src="@/assets/images/icons/icon_Image_07.png" height="70" contain/>
            </v-row>
            <v-row align="center" justify="center" class="pa-2">
              <v-card-title class="font-weight-bold text-h5 text-xs-center" style="color: #37424A">Important Information
              </v-card-title>

            </v-row>
          </v-col>
          <v-col >

            <v-row align="center" justify="center">
              <v-card flat
                      class="ma-6 mt-0">
                <v-card-subtitle class="text-body-2 pb-2 pt-0 font-weight-bold" style="color: #37424A">
                  How the data you supply is used:
                </v-card-subtitle>
                <v-card-subtitle class="text-body-2 pb-4 pt-0" style="color: #37424A">
                  By submitting a claim, you are consenting Marsh, our insurers and Deliveroo the use of your data for the purposes of processing your claim in accordance with our data protection and <a :href="openPrivacyPolicy" target="_blank" style="color: #00A8C8">Privacy Policy.</a>
                </v-card-subtitle>
                <v-card-subtitle class="text-body-2 pb-4 pt-0 font-weight-bold" style="color: #37424A">Please prepare the following documents and information
                  before starting the application:
                </v-card-subtitle>
                <v-card-text class="text-body-2" >
                  <ul style="color: #00A8C8;">
                    <li>Your Rider ID</li>
                    <li>{{ this.getAgreementIdContent }}</li>
                    <li>Rider Information</li>
                    <li>Medical Receipts, Medical Reports and any other documents
                      related to your injury
                    </li>
                  </ul>
                </v-card-text>
                <v-card-subtitle class="text-body-2 pt-2 font-weight-bold" style="color: #37424A">It is important for you to support and cooperate in the claim process and comply with the following requirements.</v-card-subtitle>
                <v-card-text class="text-body-2" style="color: #37424A">
                  <ul style="list-style-type: decimal">
                    <li>All claims must be reported to the claims administrator as soon as possible, and within 30 days of the accident. If a claim cannot be reported within 30 days, you must provide a valid reason for the delay.</li>
                    <li>You must complete the claim form and provide any information and assistance which we may require in establishing the amount of any payment or settlement due.</li>
                    <li>You must not discuss or accept liability or responsibility to third parties verbally or in writing. You should simply direct them to us and we will handle matters for you.</li>
                  </ul>
                </v-card-text>
                <v-card-text class="text-body-2 my-0 py-0" style="color: #37424A">
                  Please note that if requirements are not adhered to, it could affect our ability to fully assess the claim or manage the costs of claims. Therefore, we may not be able to admit the claim or  payment could be reduced.
                </v-card-text>
              </v-card>
            </v-row>

          </v-col>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout class="d-none d-lg-flex mb-8">
      <v-flex lg2></v-flex>
      <v-flex xs12 lg8>
        <v-row>
          <v-col offset="3" cols="3" class=" pr-2">
            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#93A1B4"
                class="white--text"
                style="text-transform: none !important; font-size: 20px"
                @click="goPrevious()"
            >
              <v-icon>{{ 'mdi-chevron-left' }}</v-icon>
              Previous
            </v-btn>
          </v-col>
          <v-col cols="3" class="pl-2">

            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#00CCBC"
                class="white--text"
                style="text-transform: none !important; font-size: 20px"
                :to="'/claim-coverage'"
            >Next
              <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
export default {
  name: "claim-agreement",
  data() {
    return {
      isAgreed: false
    }
  },
  methods: {
    initPageTitle: function () {
      this.$store.commit('changeMenuTitle', 'Make a Claim')
    },
    hideClaimProgressBar: function () {
      this.$store.commit("changeShowClaimProgressBarStatus", false);
    },
    goPrevious() {
      this.$router.back()
    }
  },
  computed: {
    getAgreementIdContent() {
      if(this.$store.state.country !== 'Singapore'){
        return 'Hong Kong ID and/or Passport photocopies'
      }
      return 'National Registration ID Card and/or Passport photocopies'
    },
    openPrivacyPolicy () {
      let country = this.$store.state.country
      let language = this.$store.state.selectedLanguageCode
      switch (country) {
        case 'Hong Kong':

          if (language === 'hk') {
            // eslint-disable-next-line
            return  'assets/pdf/Privacy Policy (Deliveroo HK).pdf'
          } else {
            // eslint-disable-next-line
            return  'assets/pdf/Privacy Policy (Deliveroo HK).pdf'
          }
          // case 'Taiwan':
          //   return './assets/pdf/Marsh HK - PICS_APR2013_Chinese.pdf'
        default:
          // eslint-disable-next-line
          return  'assets/pdf/Privacy Policy (Deliveroo SG).pdf'
      }
    },
    openTermsOfUse () {
      // eslint-disable-next-line
      let country = this.$store.state.country
      let language = this.$store.state.selectedLanguageCode
      switch (country) {
        case 'Hong Kong':
            return  'assets/pdf/Terms of Use and Access Agreement (Deliveroo HK).pdf'
          // case 'Taiwan':
          //   return './assets/pdf/Marsh HK - PICS_APR2013_Chinese.pdf'
        default:
          // eslint-disable-next-line
          return  'assets/pdf/Terms of Use and Access Agreement (Deliveroo SG).pdf'
      }

 

    },
  },
  mounted() {
    this.initPageTitle()
    this.hideClaimProgressBar()
    this.$store.dispatch('saveDeclaration', 'N')
  }
}
</script>

<style scoped>
ul {
  list-style-type: square;
}


.content-item {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 3px #0000001A;
  border: 1px solid #E1E1E1;
  border-radius: 5px;
  opacity: 1;
}
li {
  margin-bottom: 10px;
}
</style>
